import { Link } from "gatsby";
import { MDXRenderer } from "gatsby-plugin-mdx";
import React from "react";

export interface Props {
  post: {
    body: string;
    excerpt: string;
    frontmatter: {
      path: string;
      title: string;
      date: string;
    };
  };
  className?: string;
}

export const Post = ({ post, className }: Props) => {
  return (
    <div
      className={`m-2 p-6 shadow-lg text-sm bg-accent box-border prose-sm ${className}`}
    >
      <h2 className="text-brand">
        <Link to={post.frontmatter.path} state={{ showTitle: true }}>
          {post.frontmatter.title}
        </Link>
      </h2>
      <div className="mb-4 border-b">{post.frontmatter?.date}</div>
      <div className="prose lg:min-w-prose">
        <MDXRenderer>{post.body}</MDXRenderer>
      </div>
    </div>
  );
};
